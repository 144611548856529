import { render, staticRenderFns } from "./EditableTextAreaField.vue?vue&type=template&id=4f347a94&scoped=true"
import script from "./EditableTextAreaField.vue?vue&type=script&lang=js"
export * from "./EditableTextAreaField.vue?vue&type=script&lang=js"
import style0 from "./EditableTextAreaField.vue?vue&type=style&index=0&id=4f347a94&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f347a94",
  null
  
)

export default component.exports